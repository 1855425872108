import React from 'react'
import { graphql, useStaticQuery } from "gatsby"

const useCheckoutSettings = () => {

  const settings = useStaticQuery(graphql`
    query {
      sanitySiteSettings(id: {eq: "-0f217bb5-f7f6-5420-b7c6-58db2c12b8c7"}) {
        rechargeDiscount
        minShipping
      }
    }
    `)

  const rechargeDiscount = settings.sanitySiteSettings.rechargeDiscount
  const minShipping = settings.sanitySiteSettings.minShipping

  return {
    minShipping: minShipping ?? 50,
    rechargeDiscount: rechargeDiscount,
    cartTitle: 'Parcel',
    emptyCartMessage: "Your parcel is empty.",
    frequencyOptions: [
      {
        value: 2,
        text: 'Deliver every 2 weeks'
      },
      {
        value: 4,
        text: 'Deliver every 4 weeks'
      },
      {
        value: 6,
        text: 'Deliver every 6 weeks'
      },
      {
        value: 8,
        text: 'Deliver every 8 weeks'
      },
      {
        value: 10,
        text: 'Deliver every 8 weeks'
      },
      {
        value: 12,
        text: 'Deliver every 12 weeks'
      },
    ]
  }
}

export default useCheckoutSettings
