import React, { useState, useLayoutEffect, useEffect, useRef } from 'react'
import { SansDarr, Tick, Plus, Minus } from "~components/global/svg"
import PropTypes from "prop-types"

export const SelectButton = ({ children, primaryIcon, secondaryIcon, onClick, onBlur, style, highlight }) => (
  <div className="select-button" style={highlight ? {background: 'white'} : null}>
    <div className="select-button__p-icon">
    {primaryIcon}
    </div>
    <button onClick={() => onClick ? onClick() : null} onBlur={() => onBlur ? onBlur() : null} style={style}>{children}</button>
    <div className="select-button__s-icon">
      {secondaryIcon ?? <SansDarr />}
    </div>
  </div>
)

SelectButton.propTypes = {
  children: PropTypes.node.isRequired,
  primaryIcon: PropTypes.node,
  primaryIcon: PropTypes.node,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
}

export const Select = ( props ) => {
  const [optionsVisible, setOptionsVisible] = useState(false)
  const [highlight, setHighlight] = useState(false)
  const [value, setValue] = useState(props.options[0])
  const nativeSelect = useRef()
  const firstUpdate = useRef(true);

  const updateValue = newValue => {
    setOptionsVisible(false)
    setValue(newValue)
  }

  useEffect(function(){
    nativeSelect.current.value = value?.value
  }, [optionsVisible])

  useEffect(function(){
    if(props.defaultValue){
      let defaultV = props.options.find(option => option.value === props.defaultValue)
      setValue(defaultV)
    }
  }, [props.defaultValue])

  useEffect(function(){
    setHighlight(true)
    window.setTimeout(() => setHighlight(false), 400)
  }, [props.defaultValue])

  useLayoutEffect(function(){
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }
    if(props.onChange){
      props.onChange(value)
    }
    return
  }, [value])

  const updateFromNativeSelect = value => {
    let options = props.options
    let selected = options.find(option => option.value == value)
    setValue(selected)
  }

  return(
    <div className={`select ${props.mini ? 'select--mini' : ''}`} aria-hidden="true">
      {props.label &&
      <label htmlFor="select-native">{props.label}</label>
      }
      <select className="select-native" name={props.name} id="select-native" ref={nativeSelect} onChange={e => updateFromNativeSelect(e.target.value)}>
        {props.options.map(option => <option key={option.value} value={option.value}>{option.text}</option>)}
      </select>
      <SelectButton {...props} highlight={highlight} onClick={() => setOptionsVisible(!optionsVisible)}>
        {value?.text}
      </SelectButton>
      {optionsVisible &&
        <div className="select__options">
          {props.options.map(option => (
            <button className={option.value == value?.value ? 'select__option select__option--selected' : 'select__option'} onClick={() => updateValue(option)}>
              {option.text}
            </button>
          ))}
        </div>
      }
    </div>
  )
}

Select.propTypes = {
  options: PropTypes.arrayOf(PropTypes.Object).isRequired,
  primaryIcon: PropTypes.node,
  primaryIcon: PropTypes.node,
  onChange: PropTypes.func,
}

export const RadioButton = ({ children, selected, secondaryIcon, onClick, mini, style, hasTooltip }) => (
  <div className={`radio-button radio-button--${selected ? 'active': 'inactive'} ${mini ? 'radio-button--mini' : ''}`}>
    <div className="radio-button__p-icon">
    {selected ? <Tick /> :
      <svg viewBox="0 0 30 30">
        <circle cx="15" cy="15" r="14" fill="none" stroke="#BDBDBD" strokeWidth="2"/>
      </svg>
    }
    </div>
    <button onClick={() => onClick ? onClick() : null} style={style}>{children}</button>
    <div className="radio-button__s-icon" style={{pointerEvents: hasTooltip ? 'auto' : 'none'}}>
      {secondaryIcon}
    </div>
  </div>
)

RadioButton.propTypes = {
  children: PropTypes.node.isRequired,
  selected: PropTypes.bool.isRequired,
  secondaryIcon: PropTypes.node,
  onClick: PropTypes.func,
}

export const Incrementer = ({ onChange, multiplier, unitName, initialValue, mini, className }) => {
  const [value, setValue] = useState(0)

  useEffect(() => {
    setValue(initialValue ?? 1)
  }, [initialValue])

  const updateValue = newValue => {
    if(newValue > 0 && newValue <= 99){
      setValue(newValue)
      if(onChange){
        onChange(newValue)
      }
    }
    return
  }

  return (
    <div className={`incrementer ${multiplier ? 'incrementer--has-multiplier' : ''} ${mini ? 'incrementer--mini' : ''} ${className}`}>
      <button onClick={() => updateValue(value - 1)}><Minus /></button>
      <input type="text" readOnly="true" value={value}/>
      <button onClick={() => updateValue(value + 1)}><Plus /></button>
      {multiplier && unitName &&
        <span>({value * multiplier} {unitName})</span>
      }
    </div>
  )
}

Incrementer.propTypes = {
  onChange: PropTypes.func,
  multiplier: PropTypes.number,
  unitName: PropTypes.string
}

export const SubmitButton = ({ children, onClick, bg, textColor }) => (
  <button className="submit-button" onClick={onClick ? e => onClick(e) : null} style={{backgroundColor: bg ?? '#EC873E', color: textColor ?? '#ffffff'}}>
    {children}
  </button>
)

export const TooltipIcon = ({ message, inline, rightAlign }) => {
  const [active, setActive] = useState(false)
  return(
    <div className={`tooltip-icon ${inline ? 'tooltip-icon--inline' : ''}`} onMouseEnter={() => setActive(true)} onMouseLeave={() => setActive(false)}>
      ?
      {active &&
        <div className={`tooltip-icon__message ${rightAlign ? 'tooltip-icon__message--right-align' : ''}`}>
          {message}
        </div>
      }
    </div>
  )
}
