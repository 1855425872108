import ShopifyClient from 'shopify-buy'
import Cookies from 'js-cookie'
import axios from 'axios'
import { useCart } from "~context/siteContext"

const client = ShopifyClient.buildClient({
  storefrontAccessToken: process.env.GATSBY_SHOPIFY_TOKEN,
  domain: process.env.GATSBY_SHOPIFY_STORE
})

const prepareShopifyCheckout = async (cart, code, freeItemId) => {
  const checkout = await client.checkout.create()
  Cookies.set('PP_CHECKOUT_ID', checkout.id)
  Cookies.set('PP_CHECKOUT_TYPE', 'shopify')
  let lineItemsToAdd = cart.map(item => (
    {
    variantId: btoa(`gid://shopify/ProductVariant/${item.vId}`),
    quantity: item.qty,
    }
  ))
  if(code){
    // alert(freeItemId)
    lineItemsToAdd = [
      ...lineItemsToAdd,
      {
        variantId: btoa(`gid://shopify/ProductVariant/${freeItemId}`),
        quantity: 1,
      }
    ]
  }
  await client.checkout.addLineItems(checkout.id, lineItemsToAdd)
  console.log(checkout)

  let checkoutUrl = checkout.webUrl
  if(checkoutUrl.includes('myshopify.com')){
    checkoutUrl = checkoutUrl.replace('pod-parcel.myshopify.com', 'au.account.podandparcel.com')
  }
  if(code){
    // await client.checkout.addDiscount(checkout.id, code)
    // no need to add code for now, they're making it $0 product
    window.location = checkoutUrl
  } else {
    window.location = checkoutUrl
  }
}

const prepareRechargeCheckout = (cart, code, freeItemId) => {
  let items = cart.map(item => (
    {
      variant_id: item.vId,
      quantity: item.qty,
      ...(item.subscribe ? {
        cutoff_day_of_month: null,
        cutoff_day_of_week: null,
        charge_interval_frequency: item.frequency,
        order_interval_frequency: item.frequency,
        order_interval_unit: "week",
        } : {}
      ) 
    }
  ))
  if(code){
    items = [
      ...items,
      {
        variant_id: freeItemId,
        quantity: 1,
      }
    ]
  }
  // console.log(items)
  axios({
    url: '/.netlify/functions/create-recharge-checkout',
    method: 'post',
    data: {
      line_items: items
    }
  }).then(response => {
    Cookies.set('PP_CHECKOUT_ID', response.data.token)
    Cookies.set('PP_CHECKOUT_TYPE', 'recharge')

    if(response.data.webUrl){
      window.location = response.data.webUrl
    }
    else{
      alert("Recharge cannot process some items in this cart")
    }
    return null
  })
  .catch(error => console.log(error))
  return false
}

const prepareCheckout = (cart, freeItemCode, freeItemId) => {
  let hasSubs = cart.findIndex(item => item.subscribe === true)
  hasSubs = hasSubs !== -1
  if(hasSubs){
    prepareRechargeCheckout(cart, freeItemCode, freeItemId)
  }
  else{
    prepareShopifyCheckout(cart, freeItemCode, freeItemId)
  }
}

export default prepareCheckout
